<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <template v-for="field in usual_fields">
              <base-field
                  :isDisabled="field.name === 'date'"
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" v-model="record.tags" :modal_name="page.name"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import moment from "moment";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate('punchlist'),
        name: "punchlists",
        model: "punchlist"
      },
      loading: false,
      record: {},
      usual_fields: [],
      tags_required: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.record = {
        date: moment(moment.now()).format('DD-MM-YYYY'),
      };
      this.openModal(this.modal_name);
      this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          let id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
          if(id) {
            await this.$router.push("/punchlists/" + id);
          }
          this.loading = false;
        }
      }
    },
    async getProjectInfo() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/projects/short-info/" + this.record.project_id)
          .then((response) => {
            this.loading = false;
            this.record.name = response.data.name;
            this.record.location = response.data.platformName;
            this.record.requisition = response.data.afe_pef;
            this.record.afe_pef = response.data.afe_pef;
            this.record.tags = response.data.tags;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
      if(data[0] === 'project_id') {
        this.getProjectInfo();
      }
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
  },
};
</script>